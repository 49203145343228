import { Switch } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ScheduleExpiryReminderPreference } from "../../../shared/types/schema";
import * as css from "../../shared/styles/ListItemStyles.scss";

type Props = {
  preference: ScheduleExpiryReminderPreference;
  onChange: (values: ScheduleExpiryReminderPreference) => void;
};

const ScheduleExpiryItem: React.FC<Props> = ({ preference, onChange }) => {
  return (
    <div className={css.listItem}>
      <div>
        <p>
          <FormattedMessage id="settings.scheduleExpiryNotification.header" />
        </p>
        <p className={css.info}>
          <FormattedMessage id="settings.scheduleExpiryNotification.subheader" />
        </p>
      </div>
      <div className={css.switchWrapper}>
        <Switch
          id="schedule-expiry-switch"
          aria-label="schedule-expiry"
          defaultChecked={preference.active}
          onChange={({ currentTarget }) => {
            onChange({
              active: currentTarget.checked,
            });
          }}
          extraClasses={css.switch}
        />
      </div>
    </div>
  );
};

export default ScheduleExpiryItem;
