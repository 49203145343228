@use "variables" as *;

.container {
  margin-top: $spacing-9;
}

.flexContainer {
  padding-top: $spacing-4;
  display: flex;
  justify-content: space-between;
  row-gap: $spacing-4;
  flex-direction: column;

  @include on-bp(md) {
    flex-direction: row;
  }
}

.linkFlexContainer {
  display: flex;
  column-gap: $spacing-2;
  row-gap: $spacing-2;
  flex-direction: column;

  @include on-bp(md) {
    flex-direction: row;
  }
}

.text {
  color: $content-secondary-color;
  font-size: $font-size-small;
  text-decoration: none;
}
