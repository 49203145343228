@use "variables" as *;
@use "../../shared/styles/ListItemStyles.scss";

.wrapper {
  margin-top: $spacing-3;
  margin-bottom: 30px;
}

.heading {
  margin-bottom: $spacing-2;
}
