import * as React from "react";

const HeaderStagingBackground: React.FC = () => {
  return (
    <>
      {process.env.NODE_ENV !== "production" && (
        <style>
          {
            "header {background: linear-gradient(90deg, #Ffcb46 10%, var(--hcr-header-bg-color) 100%) !important; box-shadow: none !important;}"
          }
        </style>
      )}
    </>
  );
};

export default HeaderStagingBackground;
