import { Icon, IconArrowDown } from "@flixbus/honeycomb-icons-react";
import { Calendar, Input } from "@flixbus/honeycomb-react";
import { add, isBefore, isSameDay } from "date-fns";
import * as React from "react";
import { useIntl } from "react-intl";
import { localizeDate } from "../../helper/localizeDate/localizeDate";
import HandleOutsideClick from "../handleOutsideClick/HandleOutsideClick";
import * as css from "./PeriodPicker.scss";
import { getDays, getMonths } from "./helper/calendarHelper/calendarHelper";

export type PeriodPickerProps = {
  earliestStartDate: Date;
  latestEndDate: Date;
  periodStart?: Date;
  periodEnd?: Date;
  onChange: (start: Date, end: Date) => void;
  label: string;
  preventAutoFocus?: boolean;
  errorMessage?: string;
};

const PeriodPicker: React.FC<PeriodPickerProps> = ({
  earliestStartDate,
  latestEndDate,
  periodStart,
  periodEnd,
  onChange,
  label,
  preventAutoFocus = false,
  errorMessage,
}) => {
  const { formatMessage } = useIntl();
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const [showCalendar, setshowCalendar] = React.useState(false);

  const closeCalendarAndSubmitSelection = () => {
    setshowCalendar(false);
    if (startDate && endDate === undefined) {
      onChange(
        startDate,
        periodEnd && isBefore(startDate, periodEnd) ? periodEnd : startDate
      );
    } else if (startDate && endDate) {
      onChange(startDate, endDate);
    } else {
      return;
    }
    setStartDate(undefined);
    setEndDate(undefined);
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      closeCalendarAndSubmitSelection();
    }
  }, [startDate, endDate]);

  const handleDateSelect = (selected: Date) => {
    if (startDate === undefined) {
      setStartDate(selected);
      return;
    }

    if (endDate === undefined) {
      setEndDate(selected);
      return;
    }

    setStartDate(selected);
    setEndDate(undefined);
  };

  const getStartDate = (): Date => {
    if (startDate && endDate === undefined) {
      return startDate;
    }
    return earliestStartDate;
  };

  // This function is handling the maximum selectable period
  const getEndDate = (): Date => {
    if (startDate) {
      return add(startDate, { days: 31 });
    }
    return latestEndDate;
  };

  const selectedStartDate = startDate || periodStart;
  const selectedEndDate = endDate || periodEnd;

  return (
    <HandleOutsideClick onClickOutside={closeCalendarAndSubmitSelection}>
      <Input
        label={label}
        id="period-input"
        value={
          selectedStartDate && selectedEndDate
            ? isSameDay(selectedStartDate, selectedEndDate)
              ? `${localizeDate(selectedStartDate)}`
              : `${localizeDate(selectedStartDate)} - ${localizeDate(selectedEndDate)}`
            : ""
        }
        readOnly
        onFocus={() => setshowCalendar(true)}
        tabIndex={preventAutoFocus ? -1 : undefined}
        placeholder={formatMessage({ id: "general.select" })}
        iconRight={<Icon InlineIcon={IconArrowDown} />}
        valid={errorMessage ? false : undefined}
        infoError={errorMessage || undefined}
      />
      <Calendar
        hidden={!showCalendar}
        id="period-calendar"
        appearance="compact"
        startDate={getStartDate()}
        endDate={getEndDate()}
        startSelected={selectedStartDate}
        endSelected={selectedEndDate}
        defaultMonth={selectedStartDate}
        handleSelect={handleDateSelect}
        days={getDays()}
        months={getMonths()}
        extraClasses={css.calendar}
      />
    </HandleOutsideClick>
  );
};

export default PeriodPicker;
