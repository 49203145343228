/**
 * Returns the dimensions of the target container to calculate smart position against
 * @param window
 * @param container
 * @return {height: number, width: number}
 */
function getContainerDimensions(window, container) {
  if (!container) {
    return {
      height: window.innerHeight,
      width: window.innerWidth
    };
  }
  var containerBoundingRect = container.getBoundingClientRect();
  return {
    height: containerBoundingRect.height,
    width: containerBoundingRect.width
  };
}
/**
 * returns BoundingClientRect position like object,
 * if container ref is given this is calculated against it
 * otherwise browser window is used
 * @param target {RefObject}
 * @param container {RefObject}
 */
function getTargetClientRectRelativeToContainer(target, container) {
  if (container && target) {
    var targetRect = target.getBoundingClientRect();
    var containerRect = container.getBoundingClientRect();
    return {
      top: targetRect.top - containerRect.top,
      bottom: targetRect.bottom - containerRect.top,
      left: targetRect.left - containerRect.left,
      right: targetRect.right - containerRect.left,
      width: targetRect.width,
      height: targetRect.height,
      y: targetRect.top - containerRect.top,
      x: targetRect.left - containerRect.left
    };
  }
  return target === null || target === void 0 ? void 0 : target.getBoundingClientRect();
}
/**
 * calculates the size of the zones on each side from the tooltip target element
 * returns zones with their sizes ordered from the biggest to the smallest
 * @param targetPosition
 * @param containerRect
 * @param smartPositionOffset
 */
function calculateTooltipPositionZones(targetPosition, containerRect, smartPositionOffset) {
  // tooltip target divides screen into 4 squares
  // checking which square is bigger,
  // meaning which direction from target has more space available
  var _a = smartPositionOffset.top,
    offsetTop = _a === void 0 ? 0 : _a,
    _b = smartPositionOffset.bottom,
    offsetBottom = _b === void 0 ? 0 : _b,
    _c = smartPositionOffset.left,
    offsetLeft = _c === void 0 ? 0 : _c,
    _d = smartPositionOffset.right,
    offsetRight = _d === void 0 ? 0 : _d;
  var tooltipZonesSizes = {
    // top direction square
    top: (targetPosition.top - offsetTop) * containerRect.width,
    // bottom direction square
    bottom: (containerRect.height - targetPosition.bottom - offsetBottom) * containerRect.width,
    // left direction square
    left: (targetPosition.left + offsetLeft) * containerRect.height,
    // right direction square
    right: (containerRect.width - targetPosition.right - offsetRight) * containerRect.height
  };
  var zonesSorted = Object.entries(tooltipZonesSizes).sort(function (a, b) {
    if (a[1] > b[1]) {
      return -1;
    }
    if (a[1] < b[1]) {
      return 1;
    }
    return 0;
  });
  return zonesSorted;
}
/**
 * Gets the biggest zone from the list provided, that is more likely to fit the tooltip
 * Accepts allowed zones if it's needed to restrict positioning options
 * @param zonesSorted
 * @param smartPositionAllowedPositions
 */
function getMaxZone(zonesSorted, smartPositionAllowedPositions) {
  var zonesSortedAndAllowed = zonesSorted.filter(function (value) {
    return smartPositionAllowedPositions.indexOf(value[0]) > -1;
  });
  // getting the zone with max space available,
  // while also checking of it's in the list of allowed smart positioning zones
  var maxZoneDirection = zonesSortedAndAllowed[0][0];
  return maxZoneDirection;
}
/**
 * Gets zones that a relevant for tooltip alignment,
 * this mostly narrows down to returning vertical zones for horizontal max zone,
 * and horizontal if the biggest zone is vertical
 * */
function getAlignmentZones(zonesSorted, currentZoneDirection) {
  var isVerticalMaxZone = currentZoneDirection === 'top' || currentZoneDirection === 'bottom';
  return zonesSorted.filter(function (zone) {
    return isVerticalMaxZone && (zone[0] === 'left' || zone[0] === 'right') || !isVerticalMaxZone && (zone[0] === 'top' || zone[0] === 'bottom');
  });
}
/**
 * Calculates additional smart alignment based on received zone sizes
 * @param zonesSorted
 * @param smartPositionAllowedPositions
 */
function calculateSmartAlignment(zonesSorted, smartPositionAllowedPositions) {
  var maxZoneDirection = getMaxZone(zonesSorted, smartPositionAllowedPositions);
  var _a = getAlignmentZones(zonesSorted, maxZoneDirection),
    secondLargestZone = _a[0],
    thirdLargestZone = _a[1];
  // fine-tuning tooltip position by adjusting the alignment
  var calculatedAlignment = null;
  // smart alignment needs to take 2nd largest zone in consideration, to align towards that
  var secondLargestZoneName = secondLargestZone[0];
  var secondLargestZoneSize = secondLargestZone[1];
  // 3rd largest zone is needed to calculate the threshold to decide if alignment is needed,
  // we only need its size
  var thirdLargestZoneSize = thirdLargestZone[1];
  // smart alignment only takes place of alignment zones differ for more than specified number
  // this is a magic number we need, as tooltips never appear centered without this threshold
  var alignmentThreshold = 0.25;
  if (maxZoneDirection === 'left' || maxZoneDirection === 'right') {
    if (secondLargestZoneName === 'top') {
      calculatedAlignment = 'end';
    }
    if (secondLargestZoneName === 'bottom') {
      calculatedAlignment = 'start';
    }
    if (secondLargestZoneSize - thirdLargestZoneSize <= secondLargestZoneSize * alignmentThreshold) {
      calculatedAlignment = null;
    }
  }
  if (maxZoneDirection === 'top' || maxZoneDirection === 'bottom') {
    if (secondLargestZoneName === 'right') {
      calculatedAlignment = 'start';
    }
    if (secondLargestZoneName === 'left') {
      calculatedAlignment = 'end';
    }
    if (secondLargestZoneSize - thirdLargestZoneSize <= secondLargestZoneSize * alignmentThreshold) {
      calculatedAlignment = null;
    }
  }
  return calculatedAlignment;
}

export { calculateSmartAlignment, calculateTooltipPositionZones, getAlignmentZones, getContainerDimensions, getMaxZone, getTargetClientRectRelativeToContainer };
