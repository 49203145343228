import { LocaleList, LocaleListItem } from "@flixbus-phx/marketplace-common";
import { Language } from "../../../shared/types/schema";

export const userLanguageLocales: LocaleList = [
  {
    locale: "en_US",
    name: "English",
    flagName: "us",
    bcp47Locale: "en-US",
    iso6391: "en",
    drupal: "en-us",
  },
  {
    locale: "de",
    name: "Deutsch",
    flagName: "de",
    bcp47Locale: "de",
    iso6391: "de",
    drupal: "de",
  },
  {
    locale: "hr",
    name: "Hrvatski",
    flagName: "hr",
    bcp47Locale: "hr",
    iso6391: "hr",
    drupal: "hr",
  },
  {
    locale: "fr",
    name: "Français",
    flagName: "fr",
    bcp47Locale: "fr",
    iso6391: "fr",
    drupal: "fr",
  },
  {
    locale: "it",
    name: "Italiano",
    flagName: "it",
    bcp47Locale: "it",
    iso6391: "it",
    drupal: "it",
  },
  {
    locale: "pl",
    name: "Polski",
    flagName: "pl",
    bcp47Locale: "pl",
    iso6391: "pl",
    drupal: "pl",
  },
  {
    locale: "pt_PT",
    name: "Português",
    flagName: "pt",
    bcp47Locale: "pt",
    iso6391: "pt",
    drupal: "pt-pt",
  },
  {
    locale: "es",
    name: "Español",
    flagName: "es",
    bcp47Locale: "es",
    iso6391: "es",
    drupal: "es",
  },
  {
    locale: "uk",
    name: "Українська",
    flagName: "ua",
    bcp47Locale: "uk",
    iso6391: "uk",
    drupal: "uk",
  },
  {
    locale: "tr",
    name: "Türk",
    flagName: "tr",
    bcp47Locale: "tr",
    iso6391: "tr",
    drupal: "tr",
  },
];

export const localeToLanguage = (locale: LocaleListItem): Language => {
  const language = Object.values(Language).find(
    (lang) => lang.toLowerCase() === locale.iso6391
  );
  if (language === undefined) {
    throw new Error(`Language not found for locale: ${locale.iso6391}`);
  }
  return language;
};

export const languageToLocale = (language: Language): LocaleListItem => {
  const locale = Object.values(userLanguageLocales).find(
    (locales) => locales.iso6391 === language.toLowerCase()
  );
  if (locale === undefined) {
    throw new Error(`Locale not found for language: ${language}`);
  }
  return locale;
};
