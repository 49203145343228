import { DateTimeFormatter, LocalDate } from "@js-joda/core";

export const API_DATE_FORMAT = "yyyy-MM-dd";

export const formatDateForApi = (date: Date): string => {
  return LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate()).format(
    DateTimeFormatter.ofPattern(API_DATE_FORMAT)
  );
};

export const formatDateStringForApi = (date?: string): string => {
  if (date) {
    return LocalDate.parse(date).format(DateTimeFormatter.ofPattern(API_DATE_FORMAT));
  }
  return LocalDate.now().format(DateTimeFormatter.ofPattern(API_DATE_FORMAT));
};
