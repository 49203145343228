p {
  margin: 0;
}

.wrapper > span[class*="hcr-tooltip-target"] {
  width: 100%;
}

.textContainer {
  width: 100%;

  > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
