// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/** An enumeration of ISO 639-1 two-letter language codes. */
export enum Language {
  /** Azerbaijani */
  Az = "AZ",
  /** Belarusian */
  Be = "BE",
  /** Bulgarian */
  Bg = "BG",
  /** Bosnian */
  Bs = "BS",
  /** Czech */
  Cs = "CS",
  /** Danish */
  Da = "DA",
  /** German */
  De = "DE",
  /** Greek */
  El = "EL",
  /** English */
  En = "EN",
  /** Spanish */
  Es = "ES",
  /** Estonian */
  Et = "ET",
  /** Finnish */
  Fi = "FI",
  /** French */
  Fr = "FR",
  /** Irish */
  Ga = "GA",
  /** Croatian */
  Hr = "HR",
  /** Hungarian */
  Hu = "HU",
  /** Armenian */
  Hy = "HY",
  /** Icelandic */
  Is = "IS",
  /** Italian */
  It = "IT",
  /** Georgian */
  Ka = "KA",
  /** Kazakh */
  Kk = "KK",
  /** Luxembourgish */
  Lb = "LB",
  /** Lithuanian */
  Lt = "LT",
  /** Latvian */
  Lv = "LV",
  /** Macedonian */
  Mk = "MK",
  /** Maltese */
  Mt = "MT",
  /** Dutch */
  Nl = "NL",
  /** Norwegian */
  No = "NO",
  /** Polish */
  Pl = "PL",
  /** Portuguese */
  Pt = "PT",
  /** Romanian */
  Ro = "RO",
  /** Russian */
  Ru = "RU",
  /** Northern Sami */
  Se = "SE",
  /** Slovak */
  Sk = "SK",
  /** Slovene */
  Sl = "SL",
  /** Albanian */
  Sq = "SQ",
  /** Serbian */
  Sr = "SR",
  /** Swedish */
  Sv = "SV",
  /** Turkish */
  Tr = "TR",
  /** Ukrainian */
  Uk = "UK",
}

export type Mutation = {
  __typename?: "Mutation";
  updateUserPreference: User;
};

export type MutationUpdateUserPreferenceArgs = {
  language: Language;
  preference: PreferenceInput;
};

export type Preference = {
  __typename?: "Preference";
  ridesFullyBooked: RidesFullyBookedPreference;
  scheduleExpiryReminder: ScheduleExpiryReminderPreference;
  productNews: ProductNewsPreference;
};

export type PreferenceInput = {
  ridesFullyBooked: RidesFullyBookedPreferenceInput;
  scheduleExpiryReminder: ScheduleExpiryReminderPreferenceInput;
  productNews: ProductNewsPreferenceInput;
};

export type ProductNewsPreference = {
  __typename?: "ProductNewsPreference";
  active: Scalars["Boolean"]["output"];
};

export type ProductNewsPreferenceInput = {
  active: Scalars["Boolean"]["input"];
};

export type Query = {
  __typename?: "Query";
  getUser: User;
};

export type RidesFullyBookedPreference = {
  __typename?: "RidesFullyBookedPreference";
  active: Scalars["Boolean"]["output"];
  utilization: Scalars["Int"]["output"];
};

export type RidesFullyBookedPreferenceInput = {
  active: Scalars["Boolean"]["input"];
  utilization: Scalars["Int"]["input"];
};

export type ScheduleExpiryReminderPreference = {
  __typename?: "ScheduleExpiryReminderPreference";
  active: Scalars["Boolean"]["output"];
};

export type ScheduleExpiryReminderPreferenceInput = {
  active: Scalars["Boolean"]["input"];
};

export type User = {
  __typename?: "User";
  id: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  mail?: Maybe<Scalars["String"]["output"]>;
  preference: Preference;
  language: Language;
};
