@use "variables" as *;

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.switchWrapper {
  display: flex;
  align-items: center;
}

.switch {
  line-height: 0;
}

.info {
  color: $content-secondary-color;
  font-size: $font-size-small;
}
