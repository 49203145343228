import { Heading } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./SettingsHeader.scss";

type Props = {
  name: string;
  mail: string;
};

const SettingsHeader: React.FC<Props> = ({ name, mail }) => {
  return (
    <div className={css.wrapper}>
      <Heading size={1} extraClasses={css.heading} sectionHeader>
        {name}
      </Heading>
      <p className={css.info}>{mail}</p>
    </div>
  );
};

export default SettingsHeader;
