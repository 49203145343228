import { MsalProvider } from "@azure/msal-react";
import * as React from "react";
import { TranslateFunctionProp } from "../../helper/types";
import AuthTemplate from "../AuthTemplate/AuthTemplate";
import { msalInstance } from "../config/authConfig";

const AuthProvider: React.FC<TranslateFunctionProp> = ({ translate, children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthTemplate translate={translate}>{children}</AuthTemplate>
    </MsalProvider>
  );
};

export default AuthProvider;
