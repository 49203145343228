import { IconHomeSolid } from "@flixbus/honeycomb-icons-react";
import { HeaderSubNavigation, NavItem, NavItemComp } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useMatch } from "react-router-dom";
import { Feature, hasUserPermission } from "../../../auth";
import {
  DataReportType,
  mpcPathnames,
  mpoPathnames,
  musPathnames,
} from "../../../helper";
import App from "../types";

const getNavigationItems = (app: App): NavItemComp[] => {
  const pathnames =
    app === App.MPC ? mpcPathnames : app === App.MPO ? mpoPathnames : musPathnames;
  const navItems = [];

  navItems.push(
    <NavItem
      key="home"
      href={app === App.MPC ? undefined : pathnames.userHome}
      to={app === App.MPC ? pathnames.userHome : undefined}
      RouterLink={app === App.MPC ? NavLink : undefined}
      InlineIcon={IconHomeSolid}
      shouldCloseDialog
      // TODO: remove the inline css when this is done: https://flixtech.atlassian.net/servicedesk/customer/portal/115/HNY-320
      style={{ gap: "0" }}
    >
      {" "}
    </NavItem>,
    <NavItem
      key="capacity-manager"
      href={app === App.MPO ? undefined : pathnames.capacityManager}
      to={app === App.MPO ? pathnames.capacityManager : undefined}
      RouterLink={app === App.MPO ? NavLink : undefined}
      shouldCloseDialog
    >
      <FormattedMessage id="navigation.capacityManager" />
    </NavItem>,
    <NavItem
      key="booking-info"
      href={app === App.MPO ? undefined : pathnames.bookingInfo}
      to={app === App.MPO ? pathnames.bookingInfo : undefined}
      RouterLink={app === App.MPO ? NavLink : undefined}
      shouldCloseDialog
    >
      <FormattedMessage id="navigation.bookingInfo" />
    </NavItem>
  );

  if (hasUserPermission(Feature.VIEW_POWER_BI_REPORT)) {
    navItems.push(
      <NavItem
        {...(useMatch(`${pathnames.dataReports}/*`) ? { "aria-current": "page" } : {})}
        key="data-reports"
        subMenu={
          <HeaderSubNavigation id="data-reports-sub-navigation">
            {Object.values(DataReportType).map((value) => {
              const path = `${pathnames.dataReports}/${value}`;
              return (
                <NavItem
                  key={path}
                  href={app === App.MPO ? undefined : path}
                  to={app === App.MPO ? path : undefined}
                  RouterLink={app === App.MPO ? NavLink : undefined}
                  shouldCloseDialog
                >
                  {value === DataReportType.GrossRevenue ? (
                    <FormattedMessage id="navigation.dataReports.grossRevenue" />
                  ) : (
                    <FormattedMessage id="navigation.dataReports.segmentDistance" />
                  )}
                </NavItem>
              );
            })}
          </HeaderSubNavigation>
        }
      >
        <FormattedMessage id="navigation.dataReports" />
      </NavItem>
    );
  }
  navItems.push(
    <NavItem
      key="faq"
      href={app === App.MPO ? undefined : pathnames.faq}
      to={app === App.MPO ? pathnames.faq : undefined}
      RouterLink={app === App.MPO ? NavLink : undefined}
      shouldCloseDialog
    >
      <FormattedMessage id="faq.headline" />
    </NavItem>,
    <NavItem
      key="show-stations"
      href={app === App.MPC ? undefined : pathnames.showStations}
      to={app === App.MPC ? pathnames.showStations : undefined}
      RouterLink={app === App.MPC ? NavLink : undefined}
      shouldCloseDialog
    >
      <FormattedMessage id="navigation.citiesAndStations" />
    </NavItem>
  );

  return navItems;
};

export default getNavigationItems;
