import { useState, useLayoutEffect } from 'react';
import { DEFAULT_ALLOWED_POSITIONS } from './types.js';
import { getContainerDimensions, getTargetClientRectRelativeToContainer, calculateTooltipPositionZones, getMaxZone, calculateSmartAlignment } from './smart-position-utils.js';

/**
 * @since 10.9.0
*
 * @see [Tooltip](https://honeycomb-react.hive.flix.tech/#/Components/Tooltip), [Dropdown](https://honeycomb-react.hive.flix.tech/#/Components/Dropdown)
 *
 * Calculates smart position for tooltips based on position of the target element on the screen or
 * within a certain DOM element.
 */
function useSmartPosition(_a) {
  var active = _a.active,
    alignment = _a.alignment,
    position = _a.position,
    smartPositionRef = _a.smartPositionRef,
    _b = _a.smartPositionOffset,
    smartPositionOffset = _b === void 0 ? {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    } : _b,
    _c = _a.smartPositionAllowedPositions,
    smartPositionAllowedPositions = _c === void 0 ? DEFAULT_ALLOWED_POSITIONS : _c,
    targetRef = _a.targetRef,
    elementRef = _a.elementRef;
  // @todo figure why defaults only work until the smart position is applied,
  // once set only smart values are used (tried different things, none worked properly).
  var _d = useState(position),
    smartPositionValue = _d[0],
    setSmartPositionValue = _d[1];
  var _e = useState(alignment),
    smartAlignmentValue = _e[0],
    setSmartAlignmentValue = _e[1];
  var _f = useState(),
    isOutsideOfBoundaries = _f[0],
    setIsOutsideOfBoundaries = _f[1];
  /**
   * Using IntersectionObserver API to calculate
   * if tooltip goes outside the specified container edges
   * and smart position mechanism is needed
   */
  var intersectionObserverOptions = {
    root: smartPositionRef && smartPositionRef.current ? smartPositionRef.current : null,
    rootMargin: '0px',
    threshold: 1.0
  };
  var intersectionCallback = function (entries) {
    var entry = entries[0];
    setIsOutsideOfBoundaries(!entry.isIntersecting);
  };
  /**
   * Re-calculates tooltip position value for smart positioning.
   * Runs every time element gets outside the defined container edges.
   */
  useLayoutEffect(function () {
    // preventing things breaking if SSR is happening
    // additionally we want smart positioning only happen if the refs are passed and not null
    if (targetRef && elementRef && typeof window !== 'undefined' && typeof window.document.createElement !== 'undefined') {
      // eslint-disable-next-line no-new
      var observer_1 = new IntersectionObserver(intersectionCallback, intersectionObserverOptions);
      if (elementRef && elementRef.current) {
        observer_1.observe(elementRef.current);
      }
      // only apply "smart" positioning mechanism is tooltip goes outside of container bounds
      if (isOutsideOfBoundaries === true) {
        var containerRect = getContainerDimensions(window, smartPositionRef === null || smartPositionRef === void 0 ? void 0 : smartPositionRef.current);
        var targetPosition = getTargetClientRectRelativeToContainer(targetRef.current, smartPositionRef === null || smartPositionRef === void 0 ? void 0 : smartPositionRef.current);
        var zonesSorted = calculateTooltipPositionZones(targetPosition, containerRect, smartPositionOffset);
        // getting a zone with the max space available,
        // while also checking if it's in the list of allowed smart positioning zones
        var maxZoneDirection = getMaxZone(zonesSorted, smartPositionAllowedPositions);
        // showing "smart" tooltip in the direction with max space available
        setSmartPositionValue(maxZoneDirection);
        // only align when needed, when the difference between the zones is big enough
        var calculatedAlignment = calculateSmartAlignment(zonesSorted, smartPositionAllowedPositions);
        if (calculatedAlignment !== null) {
          setSmartAlignmentValue(calculatedAlignment);
        }
      }
      return function () {
        if (elementRef && elementRef.current) {
          observer_1.unobserve(elementRef.current);
        }
      };
    }
    return function () {};
  }, [active]);
  return [smartPositionValue, smartAlignmentValue];
}

export { useSmartPosition as default };
